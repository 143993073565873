<template>
  <section>
    <b-card>
      <b-card-title>
        Notícias e avisos
      </b-card-title>

      <b-card-text>
        <b-row>
          <b-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
          >
            <b-alert
              :variant="item.color"
              show
            >
              <div class="alert-body">
                <span v-html="item.text" />
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BRow, BCol, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardTitle, BCardText, BRow, BCol, BAlert
  },
  data: () => ({
    items: []
  }),
  mounted () {
    this.getNews()
  },
  methods: {
    async getNews () {
      const url = '/user/news'

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
    }
  }
}
</script>

<style>
.ql-align-center {
  text-align: center;
}
</style>
